import { Logo } from '@/components/shared/Logo';
import { useAuth } from '@/lib/hooks/useAuth';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';

export const LeftNav = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="flex items-center justify-between gap-3 md:gap-8 h-full">
      {isAuthenticated && <MobileMenu />}
      <div className="h-full py-2">
        <Logo className="max-w-24 sm:max-w-28 h-full" />
      </div>
      {isAuthenticated && <DesktopMenu />}
    </div>
  );
};
