import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaXTwitter,
  FaYoutube,
} from 'react-icons/fa6';

import { useSettingsStore } from '@/store';

const socialIcons: Record<string, JSX.Element> = {
  x: <FaXTwitter size={18} className="text-muted-foreground" />,
  linkedin: <FaLinkedinIn size={18} className="text-muted-foreground" />,
  instagram: <FaInstagram size={18} className="text-muted-foreground" />,
  facebook: <FaFacebook size={18} className="text-muted-foreground" />,
  youtube: <FaYoutube size={18} className="text-muted-foreground" />,
};

export const Footer = () => {
  const { settings } = useSettingsStore();

  const socialLinks = settings?.ui?.links?.socialLinks || [];
  const footerLinks = settings?.ui?.links?.footerLinks || [];

  return (
    <footer className="flex md:flex-row flex-col-reverse justify-center gap-3 items-center text-sm md:justify-between px-4 sm:px-6 md:px-8 py-4 border-t">
      <p>
        Copyright © {new Date().getFullYear()} {settings?.ui?.organizationName}
      </p>
      <div className="flex gap-4 items-center justify-center">
        {socialLinks.map((link) => (
          <a
            key={link.platform}
            href={link.url}
            target="_blank"
            rel="noreferrer"
            className="text-muted-foreground"
          >
            {socialIcons[link.platform as string]}
          </a>
        ))}
        <p className="flex items-center divide-x-2">
          {footerLinks.map((link) => (
            <a
              key={link.label}
              href={link.url}
              target="_blank"
              rel="noreferrer"
              className="text-muted-foreground px-2"
            >
              {link.label}
            </a>
          ))}
        </p>
      </div>
    </footer>
  );
};
