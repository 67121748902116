import { Link } from 'react-router-dom';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { PATHS } from '@/constants/page-paths';
import { useGetProfile } from '@/lib/hooks/api/profile.hook';
import { useAuth } from '@/lib/hooks/useAuth';
import { useSettingsStore, useTokenStore, useUserStore } from '@/store';
import { useAppConfig } from '../layouts/AppConfigProvider';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Switch } from '../ui/switch';

interface ProfileMenuList {
  title: string;
  path: string;
}

const profileMenus: ProfileMenuList[] = [
  { title: 'Profile', path: PATHS.PROFILE },
  { title: 'Settings', path: `${PATHS.PROFILE}?tab=settings` },
  { title: 'Vault', path: `${PATHS.PROFILE}?tab=vault` },
];

export function ProfileDropdown() {
  const { signOut } = useAuth();
  const { user } = useUserStore();
  const { token } = useTokenStore();
  const { data } = useGetProfile({
    enabled: !!token,
  });

  const { colorScheme } = useAppConfig();

  const profile = data?.data?.data ?? user;
  const myTwinStatus = data?.data?.data?.twin?.status;

  const { setThemeMode, themeMode } = useSettingsStore();

  const toggleTheme = () => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light');
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <div
          role="button"
          className="flex items-center h-full rounded-none gap-3 hover:bg-secondary transition hover:text-secondary-foreground px-4"
        >
          {profile?.firstName && (
            <h5 className="capitalize hidden md:block">
              {profile?.firstName as string}
            </h5>
          )}

          <Avatar>
            <AvatarImage
              src={profile?.userImage as string}
              alt="profile"
              className="w-full h-full object-cover"
            />
            <AvatarFallback className="text-2xl uppercase">
              {profile?.name?.[0] as string}
            </AvatarFallback>
          </Avatar>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="min-w-[200px]" align="end">
        <DropdownMenuLabel>
          <p>{profile?.name as string}</p>
          <p className="text-muted-foreground text-sm">
            {profile?.email as string}
          </p>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {profileMenus.map((menu, i) => (
            <DropdownMenuItem key={i} className="p-0">
              <Link to={menu.path} className="w-full h-full px-2 py-1.5">
                {menu.title}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>

        {colorScheme === 'auto' && (
          <>
            <DropdownMenuItem
              className="w-full flex justify-between items-center cursor-pointer"
              onClick={() => toggleTheme()}
            >
              <span>Dark Mode</span>
              <Switch
                checked={themeMode === 'dark'}
                onCheckedChange={toggleTheme}
              />
            </DropdownMenuItem>
          </>
        )}
        {!myTwinStatus && (
          <Link to={`/my-twin`} className="inline-block md:hidden">
            <DropdownMenuItem className="w-full cursor-pointer">
              Get your own Twin
            </DropdownMenuItem>
          </Link>
        )}
        <DropdownMenuSeparator />
        <Link to={`/feedback`}>
          <DropdownMenuItem className="w-full cursor-pointer">
            Help & Feedback
          </DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="w-full cursor-pointer"
          onClick={() => signOut()}
        >
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
