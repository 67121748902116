import { useSearchParams } from 'react-router-dom';

import { useTwin } from '@/lib/hooks/api/twin.hook';
import { useSettingsStore } from '@/store';
import { ContentLoader } from '../loaders/content-loader';
import { Card, CardContent } from '../ui/card';

function AuthSectionImage() {
  const [searchParams] = useSearchParams();

  const twin = searchParams.get('twin');

  const { twinData, isLoading } = useTwin(twin as string);

  const { settings } = useSettingsStore();

  const coverImage = settings?.ui?.theming?.coverImage;

  const textColor = settings?.ui?.theming?.coverImageTextColor;

  const containerStyle = {
    backgroundImage: `url(${coverImage || null})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: textColor || null,
  };

  return (
    <Card
      className={!coverImage ? 'bg-secondary text-secondary-foreground' : ''}
      style={coverImage ? containerStyle : null}
    >
      <CardContent className="flex flex-col h-full pt-6 items-center justify-center gap-4 lg:gap-12">
        {isLoading ? (
          <ContentLoader
            count={6}
            circles={1}
            circleSize={32}
            className="items-center"
          />
        ) : (
          <>
            <div className="flex flex-row-reverse md:flex-col justify-around gap-8 lg:gap-12 items-center ">
              <div className="flex flex-col md:items-center items-left justify-center text-left md:text-center gap-2">
                <h5 className="md:text-xl text-xs">Enter the world of</h5>
                <h5 className="md:text-xl text-sm">
                  virtual conversation with
                </h5>
                <h3 className="md:hidden inline-block md:text-3xl text-lg">
                  {twinData?.personalityName}
                </h3>
              </div>
              <img
                src={twinData?.avatar}
                alt=""
                className="md:w-52 md:h-52 w-32 h-32 border rounded-full shadow object-cover "
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-4">
              <h3 className="hidden md:inline-block md:text-4xl text-lg">
                {twinData?.personalityName}
              </h3>
              <div className="text-center w-full lg:w-2/3 text-sm md:text-base">
                {twinData?.description}
              </div>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default AuthSectionImage;
