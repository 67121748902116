import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { PATHS } from '@/constants/page-paths';
import { apiGetTwin, apiGetTwinCategories } from '@/services/chat.api';
import { useChatStore } from '@/store';
import { invalidateQuery } from '../useQuery';

export const useTwin = (twin?: string) => {
  const { twinName } = useParams();

  const navigate = useNavigate();

  const twinParam = twin || twinName;

  const { data, ...others } = useQuery({
    queryKey: ['twin', twinName],
    queryFn: () => apiGetTwin({ twinName: twinParam }),
    onSuccess: () => {
      invalidateQuery(['twinCategories']);
    },
    onError: () => {
      navigate(PATHS.HOME);
    },
    refetchOnWindowFocus: false,
  });

  const twinData = data?.data?.data;

  return {
    twinData,
    ...others,
  };
};

export const useTwinCategories = (twin?: string) => {
  const { setCategory } = useChatStore();
  const { twinName } = useParams();

  const { isSuccess } = useTwin();

  const twinParam = twin || twinName;
  const { data, ...others } = useQuery({
    queryKey: ['twinCategories', twinName],
    queryFn: () => apiGetTwinCategories({ twinName: twinParam ?? '' }),
    onSuccess: (res) => {
      const categories = res?.data?.data?.categoryDetails;
      const cat = categories?.find(
        (i: Record<string, string>) =>
          i?.brainId === res?.data?.data?.defaultCategory?.brainId
      );

      setCategory(cat);
    },

    enabled: isSuccess && !!twinParam,
  });

  const categories = data?.data?.data?.categoryDetails || [];
  const defaultCategory = data?.data?.data?.defaultCategory;

  return {
    categories,
    defaultCategory,
    ...others,
  };
};
