import { cn } from '@/lib/utils/cn';
import { useSettingsStore } from '@/store';
import { LeftNav } from './nav-menu/LeftNav';
import { RightNav } from './nav-menu/RightNav';

export const Navbar = () => {
  const { settings } = useSettingsStore();

  const themedUi = settings?.ui?.theming?.themedUi;

  return (
    <nav
      className={cn(
        'h-16 px-4 sm:px-6 md:px-8 flex items-center justify-between border-b',
        themedUi ? 'bg-primary text-primary-foreground' : ''
      )}
    >
      <LeftNav />
      <RightNav />
    </nav>
  );
};
